import { FuseNavigationItem } from '@fuse/components/navigation';

export const orionNavigation: FuseNavigationItem = {
    id   : 'orion',
    title: 'Orion',
    type : 'collapsable',
    icon : 'heroicons_outline:cube-transparent',
    meta : {
        permission: 'orion.access',
    },
    children: [
        {
            id   : 'carrierManagement',
            title: 'CarrierManagement',
            type : 'basic',
            icon : 'feather:truck',
            link : '/orion/carrier-management',
            meta : {
                permission: 'orion.carrierManager.access',
            },
        },
        {
            id   : 'cac',
            title: 'CAC',
            type : 'basic',
            icon : 'receipt',
            link : '/orion/cac',
            meta : {
                permission: 'orion.cac.access',
            },
        },
        {
            id   : 'orders',
            title: 'Order',
            type : 'basic',
            icon : 'receipt',
            link : '/orion/order',
            meta : {
                permission: 'orion.order.access',
            },
        },
        {
            id   : 'deliveries',
            title: 'Delivery',
            type : 'basic',
            icon : 'mat_outline:app_registration',
            link : '/orion/delivery',
            meta : {
                permission: 'orion.delivery.access',
            },
        },
        {
            id   : 'withdrawals',
            title: 'Withdrawal',
            type : 'basic',
            icon : 'sync',
            link : '/orion/withdrawal',
            meta : {
                permission: 'orion.withdrawal.access',
            },
        },
        {
            id   : 'transports',
            title: 'Transport',
            type : 'basic',
            icon : 'feather:truck',
            link : '/orion/transport',
            meta : {
                permission: 'orion.transport.access',
            },
        },
        /* {
            id         : 'satelliteTransports',
            title      : 'SatelliteTransport',
            type       : 'basic',
            iconFontSet: 'material-symbols-outlined',
            icon       : 'propane',
            link       : '/orion/satellite-transport',
        }, */
        {
            id   : 'factoryPlans',
            title: 'FactoryPlanning',
            type : 'basic',
            icon : 'domain_disabled',
            link : '/orion/factory-planning',
            meta : {
                permission: 'orion.factoryPlanning.access',
            },
        },
        {
            id   : 'billing',
            title: 'Billing',
            type : 'collapsable',
            icon : 'receipt_long',
            meta : {
                permission: 'orion.billing.access',
            },
            children: [
                {
                    id   : 'aysLoadOrderInvoices',
                    title: 'AysLoadOrderInvoice',
                    type : 'basic',
                    icon : 'mat_outline:sports_bar',
                    link : '/orion/ays-load-order-invoice',
                    meta : {
                        permission: 'orion.aysLoadOrderInvoice.access',
                    },
                },
                {
                    id   : 'logistic',
                    title: 'Logistic',
                    type : 'basic',
                    icon : 'refresh',
                    link : '/orion/logistic-transaction',
                    meta : {
                        permission: 'orion.logistic.access',
                    },
                },
                {
                    id   : 'quotas',
                    title: 'Quota',
                    type : 'basic',
                    icon : 'mat_outline:attach_money',
                    link : '/orion/quota',
                    meta: {
                        permission: 'orion.quota.access',
                    }
                },
                {
                    id   : 'deliveryHesaJustifications',
                    title: 'DeliveryHesaJustification',
                    type : 'basic',
                    icon : 'mat_outline:takeout_dining',
                    link : '/orion/delivery-hesa-justification',
                    meta : {
                        permission: 'orion.deliveryHesaJustification.access',
                    }
                },
            ]
        },
        {
            id   : 'masters',
            title: 'Masters',
            type : 'collapsable',
            icon : 'settings',
            meta : {
                permission: 'orion.master.access',
            },
            children: [
                {
                    id   : 'servicePoints',
                    title: 'ServicePoint',
                    type : 'basic',
                    icon : 'local_drink',
                    link : '/orion/service-point',
                    meta : {
                        permission: 'orion.servicePoint.access',
                    },
                },
                {
                    id   : 'tanks',
                    title: 'Tank',
                    type : 'basic',
                    icon : 'inventory',
                    link : '/orion/tank',
                    meta : {
                        permission: 'orion.tank.access',
                    },
                },
                {
                    id   : 'dispatchCenters',
                    title: 'DispatchCenter',
                    type : 'basic',
                    icon : 'domain',
                    link : '/orion/dispatch-center',
                    meta : {
                        permission: 'orion.dispatchCenter.access',
                    },
                },
                {
                    id   : 'warehouses',
                    title: 'Warehouse',
                    type : 'basic',
                    icon : 'mat_outline:home_work',
                    link : '/orion/warehouse',
                    meta : {
                        permission: 'orion.warehouse.access',
                    },
                },
                {
                    id   : 'dealers',
                    title: 'Dealer',
                    type : 'basic',
                    icon : 'mat_outline:account_box',
                    link : '/orion/dealer',
                    meta : {
                        permission: 'orion.dealer.access',
                    },
                },
                {
                    id   : 'factories',
                    title: 'Factory',
                    type : 'basic',
                    icon : 'corporate_fare',
                    link : '/orion/factory',
                    meta : {
                        permission: 'orion.factory.access',
                    },
                },
                {
                    id   : 'products',
                    title: 'Product',
                    type : 'basic',
                    icon : 'inventory_2',
                    link : '/orion/product',
                    meta : {
                        permission: 'orion.product.access',
                    },
                },
                {
                    id   : 'trucks',
                    title: 'Truck',
                    type : 'basic',
                    icon : 'local_shipping',
                    link : '/orion/truck',
                    meta : {
                        permission: 'orion.truck.access',
                    },
                },
                {
                    id   : 'satellites',
                    title: 'Satellite',
                    type : 'basic',
                    icon : 'device_hub',
                    link : '/orion/satellite',
                    meta : {
                        permission: 'orion.satellite.access',
                    },
                },
                {
                    id   : 'carriers',
                    title: 'Carrier',
                    type : 'basic',
                    icon : 'markunread_mailbox',
                    link : '/orion/carrier',
                    meta : {
                        permission: 'orion.carrier.access',
                    },
                },
                {
                    id   : 'contacts',
                    title: 'Contact',
                    type : 'basic',
                    icon : 'groups',
                    link : '/orion/contact',
                    meta : {
                        permission: 'orion.contact.access',
                    },
                },
                {
                    id   : 'telephoneOperators',
                    title: 'TelephoneOperator',
                    type : 'basic',
                    icon : 'support_agent',
                    link : '/orion/telephone-operator',
                    meta : {
                        permission: 'orion.telephoneOperator.access',
                    },
                },
                {
                    id   : 'rates',
                    title: 'Rates',
                    type : 'collapsable',
                    icon : 'track_changes',
                    meta : {
                        permission: 'orion.rates.access',
                    },
                    children: [
                        {
                            id   : 'saleRateParent',
                            title: 'SaleRate',
                            type : 'collapsable',
                            icon : 'mat_outline:payments',
                            meta: {
                                permission: 'orion.saleRateParent.access',
                            },
                            children: [
                                {
                                    id   : 'saleRates',
                                    title: 'SaleRate',
                                    type : 'basic',
                                    icon : 'mat_outline:payments',
                                    link : '/orion/sale-rate',
                                },
                                {
                                    id   : 'saleRateServices',
                                    title: 'SaleRateService',
                                    type : 'basic',
                                    icon : 'mat_outline:home_repair_service',
                                    link : '/orion/sale-rate-service',
                                    meta: {
                                        permission: 'orion.saleRateService.access',
                                    }
                                },
                                {
                                    id   : 'saleRateRateModels',
                                    title: 'SaleRateRateModel',
                                    type : 'basic',
                                    icon : 'mat_outline:category',
                                    link : '/orion/sale-rate-rate-model',
                                    meta: {
                                        permission: 'orion.saleRateRateModel.access',
                                    }
                                },
                                {
                                    id   : 'saleRateLogisticModels',
                                    title: 'SaleRateLogisticModel',
                                    type : 'basic',
                                    icon : 'mat_outline:model_training',
                                    link : '/orion/sale-rate-logistic-model',
                                    meta: {
                                        permission: 'orion.saleRateLogisticModel.access',
                                    }
                                },
                            ]
                        },
                        {
                            id   : 'transportPurchaseRateParent',
                            title: 'TransportPurchaseRate',
                            type : 'collapsable',
                            icon : 'mat_outline:payments',
                            meta: {
                                permission: 'orion.transportPurchaseRateParent.access',
                            },
                            children: [
                                {
                                    id   : 'transportPurchaseRates',
                                    title: 'TransportPurchaseRate',
                                    type : 'basic',
                                    icon : 'mat_outline:payments',
                                    link : '/orion/transport-purchase-rate',
                                    meta: {
                                        permission: 'orion.transportPurchaseRate.access',
                                    }
                                },
                                {
                                    id   : 'transportPurchaseRateServices',
                                    title: 'TransportPurchaseRateService',
                                    type : 'basic',
                                    icon : 'mat_outline:home_repair_service',
                                    link : '/orion/transport-purchase-rate-service',
                                    meta: {
                                        permission: 'orion.transportPurchaseRateService.access',
                                    }
                                },
                                {
                                    id   : 'transportPurchaseRateRateModels',
                                    title: 'TransportPurchaseRateRateModel',
                                    type : 'basic',
                                    icon : 'mat_outline:category',
                                    link : '/orion/transport-purchase-rate-rate-model',
                                    meta: {
                                        permission: 'orion.transportPurchaseRateRateModel.access',
                                    }
                                },
                                {
                                    id   : 'transportPurchaseRateLogisticModels',
                                    title: 'TransportPurchaseRateLogisticModel',
                                    type : 'basic',
                                    icon : 'mat_outline:model_training',
                                    link : '/orion/transport-purchase-rate-logistic-model',
                                    meta: {
                                        permission: 'orion.transportPurchaseRateLogisticModel.access',
                                    }
                                },
                            ]
                        },
                        {
                            id   : 'aysRates',
                            title: 'AysRate',
                            type : 'basic',
                            icon : 'mat_outline:paid',
                            link : '/orion/ays-rate',
                        },
                    ]
                },
                {
                    id   : 'territorialDirectorys',
                    title: 'TerritorialDirectory',
                    type : 'basic',
                    icon : 'mat_outline:home',
                    link : '/orion/territorial-directory',
                    meta : {
                        permission: 'orion.territorialDirectory.access',
                    },
                },
                {
                    id   : 'toleranceValues',
                    title: 'ToleranceValue',
                    type : 'basic',
                    icon : 'mat_outline:assignment_late',
                    link : '/orion/tolerance-value',
                    meta: {
                        permission: 'orion.toleranceValue.access',
                    }
                },
                {
                    id   : 'deliveryExecutionTolerancePercentages',
                    title: 'DeliveryExecutionTolerancePercentage',
                    type : 'basic',
                    icon : 'mat_outline:drag_indicator',
                    link : '/orion/delivery-execution-tolerance-percentage',
                    meta: {
                        permission: 'orion.deliveryExecutionTolerancePercentage.access',
                    }
                },
            ],
        },
    ],
};
